/* @import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@300;400;700&display=swap');

body{
  /* font-family: "Source Sans Pro", sans-serif !important; */
  font-family: 'Roboto Condensed', sans-serif !important;
}
html,
body {
  height: 100%;
  width: 100%;
  font-family: 'Roboto Condensed', sans-serif !important;
  position: "relative";
  /* background: #01031a!important; */
}
.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #464444 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #646363 !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555 !important;
}

audio {
  display: none !important;
}